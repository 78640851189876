import { StarTwoTone } from '@ant-design/icons';
import { Button, Result, Typography, message } from 'antd';
import { useState } from 'react';

import { useAuth } from '../../../providers/AuthProvider';

export const CompletedHeader = () => {
  const { updateUserData } = useAuth();
  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = useState(false);

  const handleGoToApp = async () => {
    setLoading(true);
    try {
      await updateUserData({
        onboarded: true,
      });
    } catch (error: any) {
      console.log(error);
      setLoading(false);
      messageApi.open({
        type: 'error',
        content: error.message || 'Something went wrong, please try again.',
      });
    }
  };

  return (
    <>
      {contextHolder}

      <Result
        style={{ padding: '32px 0px' }}
        icon={<StarTwoTone />}
        title={
          <Typography.Title level={3} style={{ fontWeight: 'bold' }}>
            Welcome aboard – You're all set!
          </Typography.Title>
        }
        subTitle={
          <Typography.Text>
            Thank you for sharing your details with us! You've successfully completed the setup.
            <br />
            Now, you're ready to dive into the world of streamlined task management with AITaskly.
          </Typography.Text>
        }
        extra={[
          <Button
            key="openApp"
            loading={loading}
            type="primary"
            size="large"
            onClick={handleGoToApp}>
            Go to the app
          </Button>,
        ]}
      />
    </>
  );
};
