import { Layout } from 'antd';

import { AppFooter } from '../AppFooter/AppFooter';
import { AppHeader } from '../AppHeader/AppHeader';

interface AppLayoutProps {
  children: React.ReactNode;
  activeKey?: string;
  showHeader?: boolean;
}

export const AppLayout = ({ children, activeKey, showHeader = true }: AppLayoutProps) => {
  return (
    <Layout style={{ height: '100vh' }}>
      {showHeader && <AppHeader activeKey={activeKey} />}

      <Layout.Content
        style={{
          overflow: 'scroll',
          paddingTop: 24,
          paddingRight: 32,
          paddingLeft: 32,
          paddingBottom: 12,
        }}>
        <div
          style={{
            minHeight: '100%',
            margin: '0 auto',
            padding: '24px 32px',
            width: '100%',
            maxWidth: 1400,
            backgroundColor: '#fff',
            borderRadius: 6,
          }}>
          {children}
        </div>
      </Layout.Content>

      <AppFooter />
    </Layout>
  );
};
