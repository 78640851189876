import { AppProviders } from './providers/AppProviders';
import { MainRouter } from './routes/MainRouter';

export const App = () => {
  return (
    <AppProviders>
      <MainRouter />
    </AppProviders>
  );
};
