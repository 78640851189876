import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Form, Select, Space, message } from 'antd';
import { useState } from 'react';

import { roleOptions, sizeOptions, taskManagementOptions } from './options';
import { useAuth } from '../../../providers/AuthProvider';
import { UserData } from '../../../types/UserData';

interface FirstStepValues extends Pick<UserData, 'role' | 'taskManagementTools' | 'teamSize'> {}

interface FirstStepProps {
  onFinishStep: () => void;
}

export const FirstStep = ({ onFinishStep }: FirstStepProps) => {
  const { userData, updateUserData } = useAuth();
  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: FirstStepValues) => {
    try {
      setLoading(true);
      const taskManagementTools = (values.taskManagementTools as unknown as string[])?.join('-');
      await updateUserData({
        role: values.role,
        taskManagementTools,
        teamSize: values.teamSize,
      });
      onFinishStep();
    } catch (error: any) {
      console.log(error);
      setLoading(false);
      messageApi.open({
        type: 'error',
        content: error.message || 'Something went wrong, please try again.',
      });
    }
  };

  return (
    <Form
      style={{ marginTop: 32 }}
      requiredMark={false}
      layout="vertical"
      size="large"
      initialValues={{
        taskManagementTools: userData?.taskManagementTools?.split('-') ?? [],
        role: userData?.role,
        teamSize: userData?.teamSize,
      }}
      onFinish={handleSubmit}>
      {contextHolder}

      <Form.Item
        style={{ marginBottom: 12 }}
        name="taskManagementTools"
        label="What task management app(s) do you currently use?"
        rules={[{ required: true, message: '' }]}>
        <Select
          allowClear
          disabled={loading}
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Please select all that apply"
          options={taskManagementOptions}
        />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: 12 }}
        name="role"
        label="What is your primary role in your organization?"
        rules={[{ required: true, message: '' }]}>
        <Select
          allowClear
          disabled={loading}
          style={{ width: '100%' }}
          placeholder="Please select only one"
          options={roleOptions}
        />
      </Form.Item>
      <Form.Item
        name="teamSize"
        label="How large is your team?"
        rules={[{ required: true, message: '' }]}>
        <Select
          allowClear
          disabled={loading}
          style={{ width: '100%' }}
          placeholder="Please select only one"
          options={sizeOptions}
        />
      </Form.Item>
      <div style={{ textAlign: 'center' }}>
        <Space>
          <Button type="default" disabled>
            <LeftOutlined />
            Back
          </Button>
          <Button loading={loading} type="primary" htmlType="submit">
            Next
            <RightOutlined />
          </Button>
        </Space>
      </div>
    </Form>
  );
};
