import './styles.css';
import { GoogleOutlined, LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, Form, Image, Input, Typography, message } from 'antd';
import queryString from 'query-string';
import { useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import logo from '../../assets/images/logo.svg';
import { auth, firebase } from '../../services/firebase';

export const SignupPage = () => {
  const { search } = useLocation();
  const [messageApi, contextHolder] = message.useMessage();

  const planId = useMemo(() => {
    const parsedSearch = queryString.parse(search);
    const planType = parsedSearch.plan;
    if (planType?.includes('pro')) {
      return 'pro';
    }
    return 'lite';
  }, []);
  console.log(planId);

  const [loading, setLoading] = useState(false);

  const onFinish = async (values: {
    name: string;
    email: string;
    password: string;
    terms: boolean;
  }) => {
    if (!values.terms) {
      messageApi.open({
        type: 'error',
        content: 'Please accept the terms and conditions',
      });
      return;
    }

    try {
      setLoading(true);
      const user = await auth.createUserWithEmailAndPassword(values.email, values.password);
      await user.user?.updateProfile({
        displayName: values.name,
      });
    } catch (error: any) {
      setLoading(false);
      messageApi.open({
        type: 'error',
        content: error.message,
      });
    }
  };

  const handleLoginGoogle = async () => {
    try {
      setLoading(true);
      const provider = new firebase.auth.GoogleAuthProvider();
      await auth.signInWithPopup(provider);
    } catch (error: any) {
      setLoading(false);
      messageApi.open({
        type: 'error',
        content: error.message,
      });
    }
  };

  return (
    <div className="signup">
      <div className="signup-form">
        <div className="signup-form-inner">
          {contextHolder}

          <Image width={100} src={logo} preview={false} />

          <Typography.Title style={{ marginBottom: 0, fontWeight: 'bold' }}>
            Join aiTaskly today
          </Typography.Title>
          <Typography.Text>Welcome to the future of task management!</Typography.Text>

          <Form
            style={{ marginTop: 32 }}
            requiredMark={false}
            initialValues={{ remember: true }}
            layout="vertical"
            size="large"
            onFinish={onFinish}>
            <Form.Item
              style={{ marginBottom: 12 }}
              name="name"
              label="Name"
              rules={[{ required: true, message: '' }]}>
              <Input disabled={loading} prefix={<UserOutlined />} placeholder="John Doe" />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 12 }}
              name="email"
              label="Email"
              rules={[{ required: true, message: '' }]}>
              <Input disabled={loading} prefix={<MailOutlined />} placeholder="mail@example.com" />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 12 }}
              label="Password"
              name="password"
              rules={[{ required: true, message: '' }]}>
              <Input
                disabled={loading}
                prefix={<LockOutlined />}
                type="password"
                placeholder="******"
              />
            </Form.Item>

            <Form.Item
              style={{ marginBottom: 12 }}
              rules={[{ required: true, message: '' }]}
              name="terms"
              valuePropName="checked"
              initialValue={false}>
              <Checkbox>
                I have read and agree with the{' '}
                <a href="https://aitaskly.com/privacy.html" target="_blank">
                  Terms of Use
                </a>
                {' and '}
                <a href="https://aitaskly.com/privacy.html" target="_blank">
                  Privacy Policy
                </a>
              </Checkbox>
            </Form.Item>

            <Form.Item style={{ marginBottom: 4 }}>
              <Button block loading={loading} type="primary" htmlType="submit">
                Sign up
              </Button>

              <Divider>or</Divider>

              <Button
                block
                icon={<GoogleOutlined />}
                loading={loading}
                type="default"
                onClick={handleLoginGoogle}>
                Sign up with Google
              </Button>
            </Form.Item>

            <Form.Item style={{ marginBottom: 12, textAlign: 'center' }}>
              <Typography.Text style={{ opacity: 0.8 }}>
                By signup you agree with our{' '}
                <a href="https://aitaskly.com/privacy.html" target="_blank">
                  Terms of Use
                </a>
                {' and '}
                <a href="https://aitaskly.com/privacy.html" target="_blank">
                  Privacy Policy
                </a>
              </Typography.Text>
            </Form.Item>

            <Form.Item style={{ textAlign: 'center' }}>
              Already have an account?{' '}
              <Link to="/login">
                <strong>Sign in!</strong>
              </Link>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className="signup-extra-content">
        <div className="signup-extra-content-container">
          <Typography.Title level={4} style={{ color: '#fff' }}>
            "aiTaskly has streamlined our project communication. Writing tasks is now quick and
            precise - a huge plus for our busy team."
          </Typography.Title>
          <br />
          <Typography.Text italic style={{ color: '#fff' }}>
            Maria R., Product Manager
          </Typography.Text>
        </div>
      </div>
    </div>
  );
};
