import { CheckOutlined, LeftOutlined } from '@ant-design/icons';
import { Button, Form, Select, Space, message } from 'antd';
import { useState } from 'react';

import { hearAboutUsOptions, receiveTipsOptions } from './options';
import { useAuth } from '../../../providers/AuthProvider';
import { UserData } from '../../../types/UserData';

interface SecondStepValues extends Pick<UserData, 'hearAboutUs' | 'receiveTips'> {}

interface SecondStepProps {
  onFinishStep: () => void;
  onGoBack: () => void;
}

export const SecondStep = ({ onFinishStep, onGoBack }: SecondStepProps) => {
  const { userData, updateUserData } = useAuth();
  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: SecondStepValues) => {
    try {
      setLoading(true);
      await updateUserData({
        hearAboutUs: values.hearAboutUs,
        receiveTips: values.receiveTips,
      });
      onFinishStep();
    } catch (error: any) {
      console.log(error);
      setLoading(false);
      messageApi.open({
        type: 'error',
        content: error.message || 'Something went wrong, please try again.',
      });
    }
  };

  return (
    <Form
      style={{ marginTop: 32 }}
      requiredMark={false}
      layout="vertical"
      size="large"
      initialValues={{
        hearAboutUs: userData?.hearAboutUs,
        receiveTips: userData?.receiveTips,
      }}
      onFinish={handleSubmit}>
      {contextHolder}

      <Form.Item style={{ marginBottom: 12 }} name="hearAboutUs" label="How did you hear about us?">
        <Select
          allowClear
          disabled={loading}
          style={{ width: '100%' }}
          placeholder="Please select only one"
          options={hearAboutUsOptions}
        />
      </Form.Item>
      <Form.Item
        name="receiveTips"
        label="Would you like to receive tips and best practices for task management?">
        <Select
          allowClear
          disabled={loading}
          style={{ width: '100%' }}
          placeholder="Please select one option"
          options={receiveTipsOptions}
        />
      </Form.Item>
      <div style={{ textAlign: 'center' }}>
        <Space>
          <Button type="default" onClick={onGoBack}>
            <LeftOutlined />
            Back
          </Button>
          <Button loading={loading} type="primary" htmlType="submit">
            Done
            <CheckOutlined />
          </Button>
        </Space>
      </div>
    </Form>
  );
};
