import { Navigate } from 'react-router-dom';

import { useAuth } from '../providers/AuthProvider';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { user, userData } = useAuth();

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if (!userData?.onboarded) {
    return <Navigate to="/onboarding" replace />;
  }

  return <>{children}</>;
};
