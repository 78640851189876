import { DefaultOptionType } from 'antd/lib/select';

export const taskManagementOptions: DefaultOptionType[] = [
  { label: 'Any.do', value: 'anydo' },
  { label: 'Asana', value: 'asana' },
  { label: 'Basecamp', value: 'basecamp' },
  { label: 'Chanty', value: 'chanty' },
  { label: 'ClickUp', value: 'clickup' },
  { label: 'Clockify', value: 'clockify' },
  { label: 'Flow', value: 'flow' },
  { label: 'Hitask', value: 'hitask' },
  { label: 'HubSpot', value: 'hubspot' },
  { label: 'Jira', value: 'jira' },
  { label: 'Monday', value: 'monday' },
  { label: 'Notion', value: 'notion' },
  { label: 'Pipefy', value: 'pipefy' },
  { label: 'ProofHub', value: 'proofhub' },
  { label: 'Quire', value: 'quire' },
  { label: 'Taskade', value: 'taskade' },
  { label: 'Taskque', value: 'taskque' },
  { label: 'Things', value: 'things' },
  { label: 'Todoist', value: 'todoist' },
  { label: 'Trello', value: 'trello' },
  { label: 'Wrike', value: 'wrike' },
  { label: 'Other', value: 'other' },
];

export const roleOptions: DefaultOptionType[] = [
  { label: 'Product Manager', value: 'product-manager' },
  { label: 'Project Manager', value: 'project-manager' },
  { label: 'QA Engineer', value: 'qa-engineer' },
  { label: 'Developer', value: 'developer' },
  { label: 'Team Lead', value: 'team-lead' },
  { label: 'Founder', value: 'founder' },
  { label: 'Manager', value: 'manager' },
  { label: 'Executive', value: 'executive' },
  { label: 'Other', value: 'other' },
];

export const sizeOptions: DefaultOptionType[] = [
  { label: '1-5 members', value: '1-5' },
  { label: '6-10 members', value: '6-10' },
  { label: '11-20 members', value: '11-20' },
  { label: '21-50 members', value: '21-50' },
  { label: 'More than 51 members', value: '51+' },
];
