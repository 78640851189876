import './styles.css';
import {
  AppstoreTwoTone,
  CustomerServiceTwoTone,
  DollarTwoTone,
  HomeTwoTone,
  IdcardTwoTone,
  LogoutOutlined,
} from '@ant-design/icons';
import { Button, Image, Layout, Menu, Tooltip } from 'antd';

import logo from '../../assets/images/logo.svg';
import { auth } from '../../services/firebase';
const { Header } = Layout;

interface AppHeaderProps {
  activeKey?: string;
}

const disabledTwoToneColor: [string, string] = ['#99A0BE', '#EDEEF2'];

export const AppHeader = ({ activeKey }: AppHeaderProps) => {
  return (
    <Header style={{ backgroundColor: '#fff', display: 'flex', alignItems: 'center' }}>
      <Image preview={false} src={logo} width={100} />
      <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <Menu
          mode="horizontal"
          activeKey={activeKey}
          defaultSelectedKeys={['home']}
          items={[
            {
              key: 'home',
              label: 'Home',
              icon: <HomeTwoTone />,
              style: { fontWeight: 600 },
            },
            {
              key: 'templates',
              label: 'Templates',
              icon: <AppstoreTwoTone twoToneColor={disabledTwoToneColor} />,
              disabled: true,
            },
            {
              key: 'subscription',
              label: 'Subscription',
              icon: <DollarTwoTone twoToneColor={disabledTwoToneColor} />,
              disabled: true,
            },
            {
              key: 'support',
              label: 'Support',
              icon: <CustomerServiceTwoTone twoToneColor={disabledTwoToneColor} />,
              disabled: true,
            },
            {
              key: 'profile',
              label: 'Profile',
              icon: <IdcardTwoTone twoToneColor={disabledTwoToneColor} />,
              disabled: true,
            },
          ]}
        />
      </div>
      <Tooltip title="Logout">
        <Button icon={<LogoutOutlined />} type="text" onClick={() => auth.signOut()} />
      </Tooltip>
    </Header>
  );
};
