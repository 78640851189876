import { Button, Layout } from 'antd';

import { appConfig } from '../../config/appConfig';

const { Footer } = Layout;

export const AppFooter = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Footer
      style={{
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'space-between',
        width: '100%',
        padding: '12px 24px',
      }}>
      <Button type="text" disabled>
        ©{currentYear} <strong> {appConfig.companyName}</strong>
      </Button>
      <div>
        <Button
          size="small"
          type="link"
          target="_blank"
          rel="noopener noreferrer"
          href={appConfig.websiteUrl}>
          Website
        </Button>
        <Button
          size="small"
          type="link"
          target="_blank"
          rel="noopener noreferrer"
          href={appConfig.privacyPolicyUrl}>
          Privacy Policy
        </Button>
      </div>
    </Footer>
  );
};
