import { Col, Divider, Row, Steps } from 'antd';
import { useState } from 'react';

import { CompletedHeader } from './CompletedHeader/CompletedHeader';
import { FirstStep } from './FirstStep/FirstStep';
import { OnboardHeader } from './OnboardHeader/OnboardHeader';
import { SecondStep } from './SecondStep/SecondStep';
import { AppLayout } from '../../components/AppLayout/AppLayout';

export const OnboardingPage = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [completed, setCompleted] = useState(false);

  return (
    <AppLayout showHeader={false}>
      <Row justify="center">
        <Col md={24} lg={14}>
          {completed ? <CompletedHeader /> : <OnboardHeader />}

          <Divider />
          <Steps
            size="small"
            current={currentStep}
            items={[
              { title: 'Understanding your workflow' },
              {
                title: 'Tailoring your experience',
                style: { height: 24 },
              },
            ]}
          />
          <Divider />

          {currentStep === 0 && <FirstStep onFinishStep={() => setCurrentStep(currentStep + 1)} />}
          {currentStep === 1 && (
            <SecondStep
              onGoBack={() => setCurrentStep(currentStep - 1)}
              onFinishStep={() => {
                setCurrentStep(currentStep + 1);
                setCompleted(true);
              }}
            />
          )}
        </Col>
      </Row>
    </AppLayout>
  );
};
