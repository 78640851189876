import { Col, Divider, Row, Tag, Typography } from 'antd';

import { AppLayout } from '../../components/AppLayout/AppLayout';

export const HomePage = () => {
  return (
    <AppLayout>
      {/* <Breadcrumb
        items={[
          {
            title: <HomeTwoTone />,
          },
          {
            title: 'Home',
          },
        ]}
      /> */}

      <Row justify="center" style={{ marginTop: 24 }}>
        <Col xs={24} sm={24} md={24} lg={16}>
          <Tag color="red">Important notice</Tag>
          <Typography.Title style={{ fontWeight: 'bold' }} level={1}>
            We are currently at full capacity
          </Typography.Title>
          <Typography.Text>
            We're excited to see such a fantastic response to our AI-powered task management
            solution.
            <br />
            However, we've reached our current user capacity, and we're working hard to expand our
            services to accommodate more users.
          </Typography.Text>

          <Typography.Title level={3}>You're on our waiting list</Typography.Title>
          <Typography.Text>
            While we can't offer immediate access right now, you've been automatically added to our
            waiting list.
            <br />
            As soon as we increase our capacity, you'll be among the first to know!
          </Typography.Text>

          <Typography.Title level={3}>What to expect next</Typography.Title>
          <Typography.Text>
            We'll notify you via email and in-app notifications once there's space available for new
            users.
            <br />
            In the meantime, we appreciate your patience and understanding.
          </Typography.Text>

          <Divider />

          <Typography.Text>
            If you have any questions or require further information, feel free to reach out at
            <strong> support@aitaskly.com</strong>. <br />
            Thank you for your support and understanding.
          </Typography.Text>
        </Col>
      </Row>
    </AppLayout>
  );
};
