export const firebaseConfig = {
  apiKey: 'AIzaSyD6tPQVHyBS2odRtH4OS3ZVLH0LSYwGcKo',
  authDomain: 'aitaskly.firebaseapp.com',
  projectId: 'aitaskly',
  storageBucket: 'aitaskly.appspot.com',
  messagingSenderId: '718771654810',
  appId: '1:718771654810:web:6acac38136191e234c8f23',
  measurementId: 'G-WBSMNRP1XL',
  databaseURL: 'https://aitaskly-default-rtdb.firebaseio.com',
};
