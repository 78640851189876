import { DefaultOptionType } from 'antd/lib/select';

export const hearAboutUsOptions: DefaultOptionType[] = [
  { label: 'Social media', value: 'social-media' },
  { label: 'Search engine', value: 'search-engine' },
  { label: 'Online ads', value: 'online-ads' },
  { label: 'Friends', value: 'friends' },
  { label: 'Blog or article', value: 'blog-or-article' },
  { label: 'Other', value: 'other' },
];

export const receiveTipsOptions: DefaultOptionType[] = [
  { label: "Yes, I'd love to get tips and updates", value: 'true' },
  { label: 'No, thank you', value: 'false' },
];
