import React from 'react';

import { auth, database, firebase } from '../services/firebase';
import { UserData } from '../types/UserData';

interface AuthContextData {
  user: firebase.User | null;
  userData: UserData | null;
  loading: boolean;
  updateUserData: (data: Partial<UserData>) => Promise<void>;
}

const AuthContext = React.createContext<AuthContextData>({
  user: null,
  userData: null,
  loading: true,
  updateUserData: () => Promise.resolve(),
});

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = React.useState<firebase.User | null>(null);
  const [userData, setUserData] = React.useState<UserData | null>(null);
  const [loading, setLoading] = React.useState(true);

  const firebaseRef = React.useRef<firebase.database.Reference>();

  const updateUserData = React.useCallback(async (data: Partial<UserData>) => {
    firebaseRef.current?.update(data);
  }, []);

  React.useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUser(user);
      if (user) {
        firebaseRef.current = database.ref(`users/${user.uid}`);
        firebaseRef.current.on('value', (snapshot) => {
          const data = snapshot.val();
          setUserData(data);
          setLoading(false);
        });
      } else {
        firebaseRef.current?.off('value');
        setLoading(false);
      }
    });
  }, []);

  return (
    <AuthContext.Provider value={{ user, userData, loading, updateUserData }}>
      {children as any}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
};
