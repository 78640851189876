import { ConfigProvider } from 'antd';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { AuthProvider } from './AuthProvider';
import { theme } from '../config/theme';

interface AppProvidersProps {
  children: React.ReactNode;
}

export const AppProviders = ({ children }: AppProvidersProps) => {
  return (
    <BrowserRouter>
      <ConfigProvider theme={theme}>
        <AuthProvider>{children}</AuthProvider>
      </ConfigProvider>
    </BrowserRouter>
  );
};
