import { Image, Result, Typography } from 'antd';

import icon from '../../../assets/images/icon.svg';

export const OnboardHeader = () => {
  return (
    <Result
      style={{ padding: '32px 0px' }}
      icon={<Image width={50} src={icon} preview={false} />}
      title={
        <Typography.Title level={3} style={{ fontWeight: 'bold' }}>
          Welcome to aiTaskly!
        </Typography.Title>
      }
      subTitle={
        <Typography.Text>
          Share a bit about your current task management tools and work environment.
          <br />
          This helps us customize your experience.
        </Typography.Text>
      }
    />
  );
};
