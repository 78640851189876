import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

export const LoadingPage = () => {
  return (
    <Spin
      fullscreen
      indicator={<LoadingOutlined style={{ fontSize: 60 }} spin />}
      spinning
      style={{ backgroundColor: '#E7E9F4' }}
    />
  );
};
